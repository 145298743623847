<template>
  <div v-if="loading" class="page-container">
    <div class="container-title-block">
      <div class="title-block-text">Тестирование</div>
      <div class="title-block-search">
        <SearchInput class="ml-4" v-model="search" label="Поиск тестов" />
      </div>
    </div>
    <div class="container-subtitle-block">
      <v-btn depressed class="button-blue" @click="dialogAddGroup = true">
        Добавить тест
      </v-btn>
      <div class="subtitle-block-count">
        Всего тестов: {{ itemsAll.length }}
      </div>
    </div>
    <div class="container-table">
      <Table
        :items="search && search.length != 0 ? searchingItems : itemsAll"
        :headers="headers"
      
        headerFixed
        @click:row="goToDetail"
      >
      <template v-slot:[`item.questionsCount`]="{item}">
        {{item.statistic && item.statistic.questionsCount ? item.statistic.questionsCount : '0' }}
      </template>
        <template v-slot:[`item.averagePercent`]="{item}">
        {{item.statistic && item.statistic.averagePercent ? item.statistic.averagePercent : '0' }}
      </template>
        <template v-slot:[`item.tryingPercent`]="{item}">
        {{item.statistic && item.statistic.tryingPercent ? item.statistic.tryingPercent : '0' }}
      </template>
      </Table>
    </div>
    <Dialog
      v-model="dialogAddGroup"
      refForm="testForm"
      titleText="Добавление тест"
      :mainItems="[
        {
          type: 'text',
          label: 'Название',
          value: this.newTest.name,
          vmodel: (e) => (this.newTest.name = e),
          valid: true,
        },
        {
          type: 'textarea',
          label: 'Описание',
          value: this.newTest.description,
          vmodel: (e) => (this.newTest.description = e),
        },
      ]"
      @click:success="addTest"
      :buttonsFooter="{
        success: {
          title: 'Добавить тест',
          loading: true,
        },
      }"
    >
    </Dialog>
  </div>
</template>
<script>
import SearchInput from "@/components/main/inputs/SearchInput";
export default {
  components: {
    SearchInput,
  },
  data: () => ({
    loading: true,
    search: "",
    items: [],
    itemsAll: [],
    dialogAddGroup: false,
    newTest: {},
    headers: [
      {
        text: "Название",
        align: "flex-left",
        sortable: false,
        value: "name",
      },
      {
        text: "Количество вопросов",
        align: "flex-left",
        sortable: false,
        value: "questionsCount",
      },
      {
        text: "Прохождение %",
        align: "flex-left",
        sortable: false,
        value: "tryingPercent",
      },
      {
        text: "Результат %",
        align: "flex-left",
        sortable: false,
        value: "averagePercent",
      },
    ],
  }),
  computed: {
    searchingItems() {
      return this.itemsAll.filter((i) =>
        i.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    getItems() {
      this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/test/list",
          {},
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.itemsAll = res.data
          this.loading = true;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
  
    addTest() {
      let formData = new FormData();
      formData.append("name", this.newTest.name);
      formData.append("description", this.newTest.description || "");
      this.$axios
        .post(this.$store.getters.apiAdminPanelV4 + "/test", formData, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.$router.push({ name: "Test", params: { id: res.data.id } });
          
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    goToDetail(item) {
      this.$router.push({ name: "Test", params: { id: item.id } });
    },
  },
  created() {
    this.getItems();
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  padding: 40px 64px 0px 64px;
  height: 100%;
  width: 100%;
  position: relative;
  .container-title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-block-text {
      color: var(--app-black);
      font-size: 28px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0px;
    }
    .title-block-search {
      width: 300px;
      min-width: 300px;
    }
  }
  .container-subtitle-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0px 12px 0px;
    position: relative;
    .buttons-block-search {
      min-width: 300px;
    }
    .subtitle-block-count {
      font-size: 16px;
      color: var(--app-grey-3);
    }
  }
  .container-table {
    height: calc(100vh - 180px);
    .table-icon {
      filter: invert(44%) sepia(35%) saturate(0%) hue-rotate(179deg)
        brightness(91%) contrast(80%);
      margin: 0px 4px;
    }
  }
}
</style>