var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"page-container"},[_c('div',{staticClass:"container-title-block"},[_c('div',{staticClass:"title-block-text"},[_vm._v("Тестирование")]),_c('div',{staticClass:"title-block-search"},[_c('SearchInput',{staticClass:"ml-4",attrs:{"label":"Поиск тестов"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"container-subtitle-block"},[_c('v-btn',{staticClass:"button-blue",attrs:{"depressed":""},on:{"click":function($event){_vm.dialogAddGroup = true}}},[_vm._v(" Добавить тест ")]),_c('div',{staticClass:"subtitle-block-count"},[_vm._v(" Всего тестов: "+_vm._s(_vm.itemsAll.length)+" ")])],1),_c('div',{staticClass:"container-table"},[_c('Table',{attrs:{"items":_vm.search && _vm.search.length != 0 ? _vm.searchingItems : _vm.itemsAll,"headers":_vm.headers,"headerFixed":""},on:{"click:row":_vm.goToDetail},scopedSlots:_vm._u([{key:"item.questionsCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.statistic && item.statistic.questionsCount ? item.statistic.questionsCount : '0')+" ")]}},{key:"item.averagePercent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.statistic && item.statistic.averagePercent ? item.statistic.averagePercent : '0')+" ")]}},{key:"item.tryingPercent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.statistic && item.statistic.tryingPercent ? item.statistic.tryingPercent : '0')+" ")]}}],null,true)})],1),_c('Dialog',{attrs:{"refForm":"testForm","titleText":"Добавление тест","mainItems":[
      {
        type: 'text',
        label: 'Название',
        value: this.newTest.name,
        vmodel: function (e) { return (this$1.newTest.name = e); },
        valid: true,
      },
      {
        type: 'textarea',
        label: 'Описание',
        value: this.newTest.description,
        vmodel: function (e) { return (this$1.newTest.description = e); },
      } ],"buttonsFooter":{
      success: {
        title: 'Добавить тест',
        loading: true,
      },
    }},on:{"click:success":_vm.addTest},model:{value:(_vm.dialogAddGroup),callback:function ($$v) {_vm.dialogAddGroup=$$v},expression:"dialogAddGroup"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }